import axios from "axios";
import { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Table,
  Space,
  Spin,
  Modal,
  Select,
  DatePicker,
} from "antd";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";

import { MdAddCircleOutline } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { useMyContext } from "../context/Usecontext";
import conf from "../config";
import Cookies from "js-cookie";
import { AiOutlineDelete } from "react-icons/ai";
import { LuEye } from "react-icons/lu";
const Showdata = ({ editdata, setIslogin }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchYear, setSearchYear] = useState("");
  const [originalData, setOriginalData] = useState([]);
  const [showmodal, setShowmodal] = useState(false);
  const [deleteid, setDeleteid] = useState();
  const [sortstate, setSortstate] = useState("ascending");
  const [empdetails, setEmpdetails] = useState([]);
 

  const columns = [
    // Define columns based on your form fields
    {
      title: "Year",
      dataIndex: "Year",
      key: "Year",
    },
    {
      title: "First Name",
      dataIndex: "firstname",
      key: "firstname",
    },
    {
      title: "Last Name",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              editdata(text.id, navigate);
              // console.log(text.id);
              // setEmpidforedit(text.personid);
              // navigate("/form");
            }}
            type="primary"
          >
            <FiEdit />
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setDeleteid(record.id);
              setShowmodal(true);
            }}
          >
            <AiOutlineDelete color={"red"} />
          </Button>
        </Space>
      ),
    },
  ];

  const getdata = async () => {
    try {
      setLoading(true);

      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(`${conf.baseurl}/getdata`, config);

      if (response.data == "F") {
        // console.log(response.data);
      } else {
        // console.log(response.data);
        // console.log(response.data.data);
        // setData(response.data.data)
        // const sortedData = response.data.data.sort((a, b) => b.Year - a.Year);

        const sortedData = response.data.data.sort((a, b) => {
          return a.firstname.localeCompare(b.firstname); // Sort by first name in ascending order if years are equal
        });
        setData(sortedData);
        setOriginalData(response.data.data);

        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      // console.log('Error fetching data:', error.message);
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        setLoading(false);
        console.log("Error fetching data:", error.message);
      }
    }
  };

  const getdetails = async () => {
    try {
      const token = Cookies.get("token");
      // console.log(token)
      const config = {
        headers: {
          Authorization: token,
        },
      };
      // const response = await axios.get("http://localhost:5000/api/getdetails", config)
      const response = await axios.get(`${conf.baseurl}/getdetails`, config);
      //   console.log(response.data);
      if (response.data == "F") {
        console.log("no data available");
      } else {
        const data = response.data.filter((item) => item.isAdmin != 1);
        setEmpdetails(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    getdata();
    getdetails();
    // const isAdmin=Cookies.get("isAdmin")
    // console.log(isAdmin)
    // setIsAdmin(isAdmin)
    setSearchYear("");
  }, []);

  // const handleSearch = (value) => {
  //   setSearchYear(value);
  //   if (value === "") {
  //     setData(originalData);
  //   } else {
  //     const filteredData = originalData.filter((item) =>
  //       String(item.Year).includes(String(value))
  //     );
  //     setData(filteredData);
  //   }
  // };

  const handleCancel = () => {
    setShowmodal(false);
    setDeleteid();
  };

  const [form] = Form.useForm();

  const handleReset = () => {
    console.log(originalData);
    form.resetFields();
    setData(originalData);
  };

  const deleteEmployeedata = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      // const id=data.id;

      const id = deleteid;
      // console.log(id);
      const response = await axios.delete(
        `${conf.baseurl}/delete_employeedata/${id}`,
        config
      );
      if (response.data.status === "S") {
        toast.success(response.data.message);
        setShowmodal(false);
        setDeleteid();
        getdata();
        setSearchYear("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error in deleting data:", error.message);
      }
    }
  };

  const handledata = (value) => {
    console.log(value);
    let sortedData = [...data];
    if (value === "ascending") {
      setSortstate("ascending");
      sortedData.sort((a, b) => a.firstname.localeCompare(b.firstname));
    } else if (value === "descending") {
      sortedData.sort((a, b) => b.firstname.localeCompare(a.firstname));
      setSortstate("descending");
    }
    setData(sortedData);
  };

  const onFinish = (data) => {
    console.log(data);

    let filteredData;
    console.log(originalData);
    if (data.year && data.info) {
      filteredData = originalData.filter((item) => {
        return item.personid === data.info && item.Year == data.year.$y;
      });
    } else {
      filteredData = originalData.filter((item) => {
        return item.personid === data.info || item.Year == data.year?.$y;
      });
    }
    // console.log(filteredData);

    setData(filteredData);
  };

  return (
    <div className="container">
      <Spin spinning={loading}>
        <div className="formWrapper">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "0 10px 10px 10px",
            }}
          >
            <div>
              {/* <Input.Search
                className="search-field"
                placeholder="Search by year"
                allowClear
                size="middle"
                value={searchYear}
                onChange={(e) => handleSearch(e.target.value)}
              /> */}
              <Form style={{ display: "flex" }} onFinish={onFinish} form={form}>
                <Form.Item
                  name="year"
                  // rules={[{ required: true, message: "Please enter the year" }]}
                >
                  <DatePicker
                    // allowClear={false}
                    picker="year"
                    format="YYYY"
                    onChange={(year) => {
                      console.log(year);
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item name="info">
                  <Select
                    style={{ width: 300, marginLeft: 20 }}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {empdetails.map((val) => {
                      return (
                        <Select.Option value={val.id} key={val.id}>
                          {val.fname + " " + val.lname + " " + val.mail}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Button
                  type="primary"
                  htmlType="submit"
                  className="view-btn"
                  style={{ marginLeft: 30 }}
                >
                  <LuEye size={20} />
                </Button>
                <Button
                  className="defaultBtns"
                  style={{ marginLeft: 30 }}
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Form>
            </div>
            <div style={{ display: "flex" }}>
              <Select
                style={{ borderRadius: 7, padding: "4" }}
                defaultValue={sortstate}
                onChange={handledata}
              >
                <Select.Option value="ascending">
                  Sort by ascending
                </Select.Option>
                <Select.Option value="descending">
                  Sort by descending
                </Select.Option>
              </Select>
              <Button
                className="defaultBtns"
                onClick={() => {
                  navigate("/form");
                }}
              >
                <MdAddCircleOutline />
                Add
              </Button>
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={data}
            style={{ overflow: "auto", margin: 10 }}
            pagination={false}
            align="center"
            className="grid-table"
          />

          <Modal
            open={showmodal}
            onOk={deleteEmployeedata}
            onCancel={handleCancel}
          >
            <p>Are you sure you want to delete this data ?</p>
          </Modal>
        </div>
      </Spin>
    </div>
  );
};
export default Showdata;
