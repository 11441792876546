import { useEffect, useState, useRef } from "react";
import { Button, Form, Select, Checkbox, Row, Col, Spin } from "antd";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import conf from "../config";
import { useNavigate } from "react-router-dom";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import { useMyContext } from "../context/Usecontext";

const { RangePicker } = DatePicker;

let all = [
  "Department",
  "Manager",
  "Title",
  "extrabonus",
  "Match",
  "salary",
  "lowbonusestimate",
  "highbonusestimate",
  //   "bonusestimate",
  "bonusTarget",
  "actualBonus",
  //   "actualBonusReserve",
  "medical",
  "lifeinsurance",
  "Dental",
  "vision",
  "BVSHSSF_Raleigh",
  "BVSHSSF",
  "BAAG",
  "BAAG_II",
  "BVSHSSF_Syracuse",
  "BVSEF_II",
  "BVSEF_III",
  "BVREP_IV",
  "BVREP_V",
  "gateway",
  "UCC",
  "Cal_Regents_Boston",
  "BVSHSSF_FA",
  "BVSEF_III_FA",
  "BVREP_IV_FA",
  "BVREP_V_FA",
  "BVREP_VI_FA",
  "city",
  "BVF_I",
  "Cal_Regents_Seattle",
  "OP_Trust",
  "Samsung_Syracuse",
  "Samsung_Austin",
  "Harel",
  "Stepstone",
  "Gainesville",
  "SHF_III",
  "Cal_Regents_Raleigh",
  "BVREP_IV_EPD",
  "BVREP_V_EPD",
  "BVREP_VI_EPD",
  "SHF_III_EPD",
  "BVF_I_EPD",
  "UCC_EPD",
  "Cal_Regents_Boston_EPD",
  "Cal_Regents_Syracuse_EPD",
  "Cal_Regents_Seattle_EPD",
  "Harel_EPD",
  "Gainesville_EPD",
  "OP_Trust_EPD",
  "Samsung_Syracuse_EPD",
  "Samsung_Austin_EPD",
  "Stepstone_EPD",
  "BVSHSSF_EPD",
  //   "BVREP_IV_EPD2",
  //   "BVREP_V_EPD2",
  //   "BVREP_VI_EPD2",
  //   "SHF_III_EPD2",
  //   "BVF_I_EPD2",
  //   "UCC_EPD2",
  //   "Cal_Regents_Boston_EPD2",
  //   "Cal_Regents_Syracuse_EPD2",
  //   "Cal_Regents_Seattle_EPD2",
  //   "Harel_EPD2",
  //   "Gainesville_EPD2",
  //   "OP_Trust_EPD2",
  //   "Samsung_Syracuse_EPD2",
  //   "Samsung_Austin_EPD2",
  //   "Stepstone_EPD2",
  //   "BVSHSSF_EPD2",
  "BVREP_IV_EPA",
  "BVREP_V_EPA",
  "BVREP_VI_EPA",
  "BVSHSSF_EPA",
  "SHF_III_EPA",
  "BVF_I_EPA",
  "UCC_EPA",
  "Cal_Regents_Boston_EPA",
  "Cal_Regents_Syracuse_EPA",
  "Cal_Regents_Seattle_EPA",
  "Harel_EPA",
  "Gainesville_EPA",
  "Stepstone_EPA",
  "Samsung_Austin_EPA",
  "Samsung_Syracuse_EPA",
  "OP_Trust_EPA",
  "BVAF_EPA",
  "BVCSF_EPA",
  "cohort1",
  "cohort2",
  "cohort5",
  "cohort6",
  "hurdle_income1",
  "hurdle_income2",
  "hurdle_income5",
  "hurdle_income6",
  "share1",
  "share2",
  "share5",
  "share6",
];

const Exportpage = ({ setIslogin }) => {
  const navigate = useNavigate();
  const [selectedYears, setSelectedYears] = useState([]);
  const [data, setData] = useState([]);
  const [empid, setImpid] = useState();
  const formRef = useRef(null);
  const [colmarray, setColmarray] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [allSelect, setAllselect] = useState(false);
  const [allids, setAllids] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sheetdata, setSheetdata] = useState(false);

  const getdetails = async () => {
    try {
      const token = Cookies.get("token");
      // console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      // const response = await axios.get("http://localhost:5000/api/getdetails", config)
      const response = await axios.get(`${conf.baseurl}/getdetails`, config);
      // console.log(response.data);
      if (response.data == "F") {
        console.log("no data available");
      } else {
        const data = response.data.filter((item) => item.isAdmin != 1);
        setData(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error:", error.message);
      }
    }
  };

  useEffect(() => {
    getdetails();
  }, []);

  const handleYearChange = (dates, dateStrings) => {
    console.log("Selected Years:", dateStrings);
    setSelectedYears(dateStrings);
  };
  const disabledDate = (current) => {
    // Disable dates greater than the current year
    return current && current.year() > new Date().getFullYear();
  };

  const handleEmployeeChange = (value) => {
    // console.log(value);

    // setImpid(value)
    if (value === "all") {
      const allEmployeeIds = data.map((val) => val.id);
      // console.log(allEmployeeIds);
      setAllids(allEmployeeIds);
    } else {
      // It's not an array, so directly set empid
      const empid = value;
      // console.log("Employee ID:", empid);
      setImpid(empid);
    }
  };

  const exportexcel = async () => {
    // console.log(selectedValues);
    try {
      setLoading(true);

      // if (selectedYears.length > 0 && selectedValues.length > 0 && (empid || allids) ) {
      if (!empid || allids.length == 0) {
        var allempids = data.map((val) => val.id);
        // console.log(allempids);
        setAllids(allempids);
      }
      if (selectedValues.length === 0) {
        setSelectedValues(all);
      }
      // console.log("Submitted:", selectedYears);
      const token = Cookies.get("token");
      // console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };
      // const data2 = { start: selectedYears[0], end: selectedYears[1], id:empid ? empid : allids, columnsdata: selectedValues }
      const data2 = {
        start: selectedYears[0],
        end: selectedYears[1],
        id: empid ? empid : allids.length > 0 ? allids : allempids,
        columnsdata: selectedValues.length === 0 ? all : selectedValues,
        datainsheets: sheetdata,
      };
      // console.log(data2);
      // const response = await axios.get('http://localhost:5000/api/excel', { responseType: 'blob', headers: config.headers })
      const response = await axios.post(`${conf.baseurl}/excel`, data2, {
        responseType: "blob",
        headers: config.headers,
      });
      // console.log(response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "Bluevista LLC compensation report.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setImpid();
      setColmarray([]);
      formRef.current.resetFields();
      setLoading(false);
      setSheetdata(false);
      // formRef.current.setFieldsValue({ 'checkboxFieldName': [] });
      setSelectedValues([]);
      setAllselect(false);
      formRef.current.setFieldsValue({ rangePickerFieldName: undefined });
      // }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error:", error.message);
      }
    }
  };

  const colms = [
    {
      section: "",
      columns: [
        { label: "Department", value: "Department" },
        { label: "Manager", value: "Manager" },
        { label: "Title", value: "Title" },
      ],
    },

    {
      section: "Extrabonus",
      columns: [{ label: "Extrabonus", value: "extrabonus" }],
    },
    {
      section: "Match",
      columns: [{ label: "Match", value: "Match" }],
    },

    {
      section: "Salary Bonus",
      columns: [
        { label: "Salary", value: "salary" },
        { label: "Lowbonusestimate", value: "lowbonusestimate" },
        { label: "Highbonusestimate", value: "highbonusestimate" },
        // { label: "Bonusestimate", value: "bonusestimate" },
        { label: "BonusTarget", value: "bonusTarget" },
        { label: "ActualBonus", value: "actualBonus" },
        // { label: "ActualBonusReserve", value: "actualBonusReserve" },
      ],
    },

    {
      section: "Insurance",
      columns: [
        { label: "Medical", value: "medical" },
        { label: "Lifeinsurance", value: "lifeinsurance" },
        { label: "Dental", value: "Dental" },
        { label: "Vision", value: "vision" },
      ],
    },

    {
      section: "Promote Distributions",
      columns: [
        { label: "BVSHSSF_Raleigh", value: "BVSHSSF_Raleigh" },
        { label: "BVSHSSF", value: "BVSHSSF" },
        { label: "BAAG", value: "BAAG" },
        { label: "BAAG_II", value: "BAAG_II" },
        { label: "BVSHSSF_Syracuse", value: "BVSHSSF_Syracuse" },
        { label: "BVSEF_II", value: "BVSEF_II" },
        { label: "BVSEF_III", value: "BVSEF_III" },
        { label: "BVREP_IV", value: "BVREP_IV" },
        { label: "BVREP_V", value: "BVREP_V" },
      ],
    },

    {
      section: "Fund allocations",
      columns: [
        { label: "Gateway", value: "gateway" },
        { label: "UCC", value: "UCC" },
        { label: "Cal_Regents_Boston", value: "Cal_Regents_Boston" },
        { label: "BVSHSSF_FA", value: "BVSHSSF_FA" },
        { label: "BVSEF_III_FA", value: "BVSEF_III_FA" },
        { label: "BVREP_IV_FA", value: "BVREP_IV_FA" },
        { label: "BVREP_V_FA", value: "BVREP_V_FA" },
        { label: "BVREP_VI_FA", value: "BVREP_VI_FA" },
        { label: "City", value: "city" },
        { label: "BVF_I", value: "BVF_I" },
        { label: "Cal_Regents_Seattle", value: "Cal_Regents_Seattle" },
        { label: "OP_Trust", value: "OP_Trust" },
        { label: "Samsung_Syracuse", value: "Samsung_Syracuse" },
        { label: "Samsung_Austin", value: "Samsung_Austin" },
        { label: "Harel", value: "Harel" },
        { label: "Stepstone", value: "Stepstone" },
        { label: "Gainesville", value: "Gainesville" },
        { label: "SHF_III", value: "SHF_III" },
        { label: "Cal Regents-Raleigh", value: "Cal_Regents_Raleigh" },
      ],
    },

    {
      section: "EPP Promote Distributions",
      columns: [
        { label: "BVREP_IV_EPD", value: "BVREP_IV_EPD" },
        { label: "BVREP_V_EPD", value: "BVREP_V_EPD" },
        { label: "BVREP_VI_EPD", value: "BVREP_VI_EPD" },
        { label: "SHF_III_EPD", value: "SHF_III_EPD" },
        { label: "BVF_I_EPD", value: "BVF_I_EPD" },
        { label: "UCC_EPD", value: "UCC_EPD" },
        { label: "Cal_Regents_Boston_EPD", value: "Cal_Regents_Boston_EPD" },
        {
          label: "Cal_Regents_Syracuse_EPD",
          value: "Cal_Regents_Syracuse_EPD",
        },
        { label: "Cal_Regents_Seattle_EPD", value: "Cal_Regents_Seattle_EPD" },
        { label: "Harel_EPD", value: "Harel_EPD" },
        { label: "Gainesville_EPD", value: "Gainesville_EPD" },
        { label: "OP_Trust_EPD", value: "OP_Trust_EPD" },
        { label: "Samsung_Syracuse_EPD", value: "Samsung_Syracuse_EPD" },
        { label: "Samsung_Austin_EPD", value: "Samsung_Austin_EPD" },
        { label: "Stepstone_EPD", value: "Stepstone_EPD" },
        { label: "BVSHSSF_EPD", value: "BVSHSSF_EPD" },
      ],
    },

    // {
    //   section: "EPP Promote Distributions2",
    //   columns: [
    //     { label: "BVREP_IV_EPD2", value: "BVREP_IV_EPD2" },
    //     { label: "BVREP_V_EPD2", value: "BVREP_V_EPD2" },
    //     { label: "BVREP_VI_EPD2", value: "BVREP_VI_EPD2" },
    //     { label: "SHF_III_EPD2", value: "SHF_III_EPD2" },
    //     { label: "BVF_I_EPD2", value: "BVF_I_EPD2" },
    //     { label: "UCC_EPD2", value: "UCC_EPD2" },
    //     { label: "Cal_Regents_Boston_EPD2", value: "Cal_Regents_Boston_EPD2" },
    //     {
    //       label: "Cal_Regents_Syracuse_EPD2",
    //       value: "Cal_Regents_Syracuse_EPD2",
    //     },
    //     {
    //       label: "Cal_Regents_Seattle_EPD2",
    //       value: "Cal_Regents_Seattle_EPD2",
    //     },
    //     { label: "Harel_EPD2", value: "Harel_EPD2" },
    //     { label: "Gainesville_EPD2", value: "Gainesville_EPD2" },
    //     { label: "OP_Trust_EPD2", value: "OP_Trust_EPD2" },
    //     { label: "Samsung_Syracuse_EPD2", value: "Samsung_Syracuse_EPD2" },
    //     { label: "Samsung_Austin_EPD2", value: "Samsung_Austin_EPD2" },
    //     { label: "Stepstone_EPD2", value: "Stepstone_EPD2" },
    //     { label: "BVSHSSF_EPD2", value: "BVSHSSF_EPD2" },
    //   ],
    // },

    {
      section: "EPP Promote Allocations",
      columns: [
        { label: "BVREP_IV_EPA", value: "BVREP_IV_EPA" },
        { label: "BVREP_V_EPA", value: "BVREP_V_EPA" },
        { label: "BVREP_VI_EPA", value: "BVREP_VI_EPA" },
        { label: "BVSHSSF_EPA", value: "BVSHSSF_EPA" },
        { label: "SHF_III_EPA", value: "SHF_III_EPA" },
        { label: "BVF_I_EPA", value: "BVF_I_EPA" },
        { label: "UCC_EPA", value: "UCC_EPA" },
        { label: "Cal_Regents_Boston_EPA", value: "Cal_Regents_Boston_EPA" },
        {
          label: "Cal_Regents_Syracuse_EPA",
          value: "Cal_Regents_Syracuse_EPA",
        },
        { label: "Cal_Regents_Seattle_EPA", value: "Cal_Regents_Seattle_EPA" },
        { label: "Harel_EPA", value: "Harel_EPA" },
        { label: "Gainesville_EPA", value: "Gainesville_EPA" },
        { label: "OP_Trust_EPA", value: "OP_Trust_EPA" },
        { label: "Samsung_Syracuse_EPA", value: "Samsung_Syracuse_EPA" },
        { label: "Samsung_Austin_EPA", value: "Samsung_Austin_EPA" },
        { label: "Stepstone_EPA", value: "Stepstone_EPA" },
        { label: "BVAF_EPA", value: "BVAF_EPA" },
        { label: "BVCSF_EPA", value: "BVCSF_EPA" },
      ],
    },

    {
      section: "Net Income",
      columns: [
        { label: "Cohort1", value: "cohort1" },
        { label: "Cohort2", value: "cohort2" },
        { label: "Cohort5", value: "cohort5" },
        { label: "Cohort6", value: "cohort6" },
        { label: "Hurdle_Income_1", value: "hurdle_income1" },
        { label: "Hurdle_Income_2", value: "hurdle_income2" },
        { label: "Hurdle_Income_5", value: "hurdle_income5" },
        { label: "Hurdle_Income_6", value: "hurdle_income6" },
        { label: "Share1", value: "share1" },
        { label: "Share2", value: "share2" },
        { label: "Share5", value: "share5" },
        { label: "Share6", value: "share6" },
      ],
    },
  ];

  const disabledFutureYears = (current) => {
    // Disable dates in the future, i.e., after the current year
    return current && current.year() > dayjs().year();
  };

  const onFinishFailed = (errorInfo) => {
    console.log("onFinishFailed triggered");
    if (!selectedYears || selectedYears.length !== 2) {
      // Scroll to the top of the form container
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <div className="container">
      <Spin spinning={loading}>
        <div className="formWrapper">
          <Form
            ref={formRef}
            onFinish={exportexcel}
            layout="vertical"
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={6}>
                <Form.Item
                  label="Select Years"
                  name="rangePickerFieldName"
                  rules={[{ required: true, message: "Please select years" }]}
                >
                  <RangePicker
                    picker="year"
                    onChange={handleYearChange}
                    allowClear={false}
                    disabledDate={disabledFutureYears}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Select Employee" name="employee">
                  <Select
                    onChange={handleEmployeeChange}
                    placeholder="Select employee"
                  >
                    <Select.Option value="all" key="all">
                      All Employees
                    </Select.Option>
                    {data.map((val) => (
                      <Select.Option value={val.id} key={val.id}>
                        {val.fname + " " + val.lname + " " + val.mail}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="checkboxFieldName" label="Select Columns">
                  <Checkbox
                    checked={sheetdata}
                    label="Sheets"
                    onChange={(e) => {
                      console.log(e.target.checked);
                      setSheetdata(e.target.checked);
                    }}
                  >
                    Create separate sheet for each year
                  </Checkbox>

                  <Checkbox
                    checked={allSelect}
                    options={colms}
                    label="select All"
                    onChange={(e) => {
                      e.target.checked
                        ? setSelectedValues(all)
                        : setSelectedValues([]);
                      setAllselect(e.target.checked);
                    }}
                  >
                    Select All
                  </Checkbox>

                  <Checkbox.Group
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column", // Display checkboxes in a column
                      alignItems: "flex-start", // Align checkboxes to the start of each row
                    }}
                    value={selectedValues}
                    onChange={(e) => {
                      setSelectedValues(e);
                      e.length === 90
                        ? setAllselect(true)
                        : setAllselect(false);
                    }}
                  >
                    {colms.map((val1) => (
                      <div style={{ marginTop: "10px", width: "100%" }}>
                        <h6> {val1.section}</h6>
                        <Row gutter={[16, 16]}>
                          {val1.columns?.map((val) => (
                            <Col key={val.value} span={6}>
                              <Checkbox value={val.value}>{val.label}</Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default Exportpage;
