import { Button, Form, Input } from "antd";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import conf from "../config";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { useMyContext } from "../context/Usecontext";

const Editpromotetableform = ({ setIslogin }) => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { promotedataforedit, setPromotedataforedit } = useMyContext();

  useEffect(() => {
    if (promotedataforedit != null) {
      // console.log(promotedataforedit);
      formRef.current.setFieldsValue({
        Fund: promotedataforedit.Fund,
        Amount: promotedataforedit.Amount,
        "BVCM %": promotedataforedit["BVCM %"],
        Start: promotedataforedit.Start,
        End: promotedataforedit.End,
      });
    }
  }, []);

  const onsubmit = async (data) => {
    // console.log(data);
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const editobj = {
        ...data,
        id: promotedataforedit._id,
      };
      // console.log(editobj);

      const response = await axios.put(
        `${conf.baseurl}/editpromotedata`,
        editobj,
        config
      );
      if (response.data.status === "S") {
        toast.success(response.data.message);
        navigate("/promotelist");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error in deleting data:", error.message);
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="formWrapper" style={{ width: 600 }}>
        <Form
          ref={formRef}
          name="basic"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onsubmit}
        >
          <Form.Item label="Fund" name="Fund">
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Amount"
            name="Amount"
            rules={[
              {
                pattern: /^\d*\.?\d*$/,
                message: "only numbers are allowed.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="BVCM %"
            name="BVCM %"
            rules={[
              {
                pattern: /^\d*\.?\d*$/,
                message: "only numbers are allowed.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Start"
            name="Start"
            rules={[
              {
                pattern: /^[0-9]*$/,
                message: "only numbers are allowed.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="End"
            name="End"
            rules={[
              {
                pattern: /^[0-9]*$/,
                message: "only numbers are allowed.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default Editpromotetableform;
