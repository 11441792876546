import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import conf from "../config";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

export default function NetIncomeForm({ setIslogin }) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [empid, setempId] = useState(null);
  const { id } = useParams();

  const onFinish = async (values) => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      // console.log(values, empid);
      const data = {
        ...values,
        empid,
      };
      const response = await axios.post(
        `${conf.baseurl}/addnetincome`,
        data,
        config
      );
      // console.log(response.data);
      form.resetFields();
      navigate("/employeelist");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error in deleting data:", error.message);
      }
    }
  };

  useEffect(() => {
    if (id) {
      setempId(id);
      fetchData();
    }
    fetchnetincometabledata();
  }, [id, form]);

  const fetchnetincometabledata = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getnetincometabel`,
        config
      );
      const netIncomeTableData = response.data;
      // console.log(netIncomeTableData);
      if (netIncomeTableData) {
        // Populate form fields with existing data
        form.setFieldsValue({
          cohort1: netIncomeTableData[0].Cohort1 || "",
          cohort2: netIncomeTableData[0].Cohort2 || "",
          cohort5: netIncomeTableData[0].Cohort5 || "",
          cohort6: netIncomeTableData[0].Cohort6 || "",
          hurdle_income1: netIncomeTableData[0].Hurdle_Income_1 || "",
          hurdle_income2: netIncomeTableData[0].Hurdle_Income_2 || "",
          hurdle_income5: netIncomeTableData[0].Hurdle_Income_5 || "",
          hurdle_income6: netIncomeTableData[0].Hurdle_Income_6 || "",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error in retrieving data:", error.message);
      }
    }
  };

  const fetchData = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getnetincome/${id}`,
        config
      );
      const existingData = response.data;
      // console.log(existingData);

      if (existingData) {
        // Populate form fields with existing data
        form.setFieldsValue({
          share1: existingData.share1 || "",
          share2: existingData.share2 || "",
          share5: existingData.share5 || "",
          share6: existingData.share6 || "",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");

        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        console.log("Error in retrieving data:", error.message);
      }
    }
  };

  const cohorts = [1, 2, 5, 6];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form
        className="formWrapper"
        name="net_income_form"
        layout="vertical"
        onFinish={onFinish}
        style={{ width: 900 }}
        form={form}
      >
        <Row gutter={16}>
          <Col span={8}>
            {cohorts.map((cohort) => (
              <Form.Item
                key={`cohort${cohort}`}
                label={`Cohort`}
                name={`cohort${cohort}`}
                rules={[
                  {
                    pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                    message: "Please enter a valid number.",
                  },
                ]}
              >
                <Input placeholder={`Cohort ${cohort}`} disabled />
              </Form.Item>
            ))}
          </Col>
          <Col span={8}>
            {cohorts.map((cohort) => (
              <Form.Item
                key={`share${cohort}`}
                label={`Share%`}
                name={`share${cohort}`}
                rules={[
                  {
                    pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                    message: "Please enter a valid number.",
                  },
                ]}
              >
                <Input placeholder={`Share ${cohort}%`} />
              </Form.Item>
            ))}
          </Col>
          <Col span={8}>
            {cohorts.map((cohort) => (
              <Form.Item
                key={`hurdle_income${cohort}`}
                label={`Hurdle Income`}
                name={`hurdle_income${cohort}`}
                rules={[
                  {
                    pattern: /^(\d+(\.\d*)?|\.\d+)$/,
                    message: "Please enter a valid number.",
                  },
                ]}
              >
                <Input placeholder={`Hurdle Income ${cohort}`} disabled />
              </Form.Item>
            ))}
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
