import { useEffect, useState } from "react";
import { Button, Upload, message, Table } from "antd";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { FaArrowDown } from "react-icons/fa6";
import conf from "../config";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../context/Usecontext";
const Importpage = ({ setIslogin }) => {
  // const [filedata, setFiledata] = useState()
  const [fileList, setFileList] = useState([]);
  const [show, setshow] = useState(false);
  const [tabledata, setTabledata] = useState([]);
  const navigate = useNavigate();

  const customRequest = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      // console.log(fileList);
      if (fileList.length > 0) {
        // console.log(fileList[0]);
        const formData = new FormData();

        formData.append("file", fileList[0].originFileObj);
        const response = await axios.post(
          `${conf.baseurl}/upload`,
          formData,
          config
        );
        // const response = await axios.post("http://localhost:5000/api/upload", formData, config);
        // if (response.data.status === 'S') {
        //     toast.success('Data updated successfully');
        //     setFileList([]); // Clear uploaded files
        // }
        if (response.data) {
          // console.log(response.data);
          // const columdata = Object.keys(response.data[0])
          // console.log(columdata)

          setTabledata(response.data);
          setFileList([]);
        }
      } else {
        toast.error("Select the file first");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error uploading file:", error);
        toast.error("Failed to upload file");
        console.error("Error:", error.message);
      }
    }
  };

  const column = [
    "Year",
    "SSN",
    "firstname",
    "lastname",
    "email",
    "Department",
    "Title",
    "Manager",
    "extrabonus",
    "Match",
    "Footnote",
    "Salary_Bonus.salary",
    "Salary_Bonus.lowbonusestimate",
    "Salary_Bonus.highbonusestimate",
    // 'Salary_Bonus.bonusestimate',
    "Salary_Bonus.bonusTarget",
    "Salary_Bonus.actualBonus",
    // 'Salary_Bonus.actualBonusReserve',
    "Insurance.medical",
    "Insurance.lifeinsurance",
    "Insurance.Dental",
    "Insurance.vision",
    "Promote_Distributions.BVSHSSF_Raleigh",
    "Promote_Distributions.BVSHSSF",
    "Promote_Distributions.BAAG",
    "Promote_Distributions.BAAG_II",
    "Promote_Distributions.BVSHSSF_Syracuse",
    "Promote_Distributions.BVSEF_II",
    "Promote_Distributions.BVSEF_III",
    "Promote_Distributions.BVREP_IV",
    "Promote_Distributions.BVREP_V",
    "Fund_allocations.gateway",
    "Fund_allocations.UCC",
    "Fund_allocations.Cal_Regents_Boston",
    "Fund_allocations.BVSHSSF_FA",
    "Fund_allocations.BVSEF_III_FA",
    "Fund_allocations.BVREP_IV_FA",
    "Fund_allocations.BVREP_V_FA",
    "Fund_allocations.BVREP_VI_FA",
    "Fund_allocations.city",
    "Fund_allocations.BVF_I",
    "Fund_allocations.Cal_Regents_Seattle",
    "Fund_allocations.OP_Trust",
    "Fund_allocations.Samsung_Syracuse",
    "Fund_allocations.Samsung_Austin",
    "Fund_allocations.Harel",
    "Fund_allocations.Stepstone",
    "Fund_allocations.Gainesville",
    "Fund_allocations.SHF_III",
    "Fund_allocations.Cal_Regents_Raleigh",
    "EPP_Promote_Distributions.BVREP_IV_EPD",
    "EPP_Promote_Distributions.BVREP_V_EPD",
    "EPP_Promote_Distributions.BVREP_VI_EPD",
    "EPP_Promote_Distributions.SHF_III_EPD",
    "EPP_Promote_Distributions.BVF_I_EPD",
    "EPP_Promote_Distributions.UCC_EPD",
    "EPP_Promote_Distributions.Cal_Regents_Boston_EPD",
    "EPP_Promote_Distributions.Cal_Regents_Syracuse_EPD",
    "EPP_Promote_Distributions.Cal_Regents_Seattle_EPD",
    "EPP_Promote_Distributions.Harel_EPD",
    "EPP_Promote_Distributions.Gainesville_EPD",
    "EPP_Promote_Distributions.OP_Trust_EPD",
    "EPP_Promote_Distributions.Samsung_Syracuse_EPD",
    "EPP_Promote_Distributions.Samsung_Austin_EPD",
    "EPP_Promote_Distributions.Stepstone_EPD",
    "EPP_Promote_Distributions.BVSHSSF_EPD",
    // "EPP_Promote_Distributions2.BVREP_IV_EPD2",
    // "EPP_Promote_Distributions2.BVREP_V_EPD2",
    // "EPP_Promote_Distributions2.BVREP_VI_EPD2",
    // "EPP_Promote_Distributions2.SHF_III_EPD2",
    // "EPP_Promote_Distributions2.BVF_I_EPD2",
    // "EPP_Promote_Distributions2.UCC_EPD2",
    // "EPP_Promote_Distributions2.Cal_Regents_Boston_EPD2",
    // "EPP_Promote_Distributions2.Cal_Regents_Syracuse_EPD2",
    // "EPP_Promote_Distributions2.Cal_Regents_Seattle_EPD2",
    // "EPP_Promote_Distributions2.Harel_EPD2",
    // "EPP_Promote_Distributions2.Gainesville_EPD2",
    // "EPP_Promote_Distributions2.OP_Trust_EPD2",
    // "EPP_Promote_Distributions2.Samsung_Syracuse_EPD2",
    // "EPP_Promote_Distributions2.Samsung_Austin_EPD2",
    // "EPP_Promote_Distributions2.Stepstone_EPD2",
    // "EPP_Promote_Distributions2.BVSHSSF_EPD2",
    "EPP_Promote_Allocations.BVREP_IV_EPA",
    "EPP_Promote_Allocations.BVREP_V_EPA",
    "EPP_Promote_Allocations.BVREP_VI_EPA",
    "EPP_Promote_Allocations.BVSHSSF_EPA",
    "EPP_Promote_Allocations.SHF_III_EPA",
    "EPP_Promote_Allocations.BVF_I_EPA",
    "EPP_Promote_Allocations.UCC_EPA",
    "EPP_Promote_Allocations.Cal_Regents_Boston_EPA",
    "EPP_Promote_Allocations.Cal_Regents_Syracuse_EPA",
    "EPP_Promote_Allocations.Cal_Regents_Seattle_EPA",
    "EPP_Promote_Allocations.Harel_EPA",
    "EPP_Promote_Allocations.Gainesville_EPA",
    "EPP_Promote_Allocations.OP_Trust_EPA",
    "EPP_Promote_Allocations.Samsung_Syracuse_EPA",
    "EPP_Promote_Allocations.Samsung_Austin_EPA",
    "EPP_Promote_Allocations.Stepstone_EPA",
    "EPP_Promote_Allocations.BVAF_EPA",
    "EPP_Promote_Allocations.BVCSF_EPA",
    "Other_Earnings.field1",
    "Other_Earnings.fieldvalue1",
    "Other_Earnings.field2",
    "Other_Earnings.fieldvalue2",
    "Other_Earnings.field3",
    "Other_Earnings.fieldvalue3",
  ];

  const columns = column.map((col, ind) => {
    if (ind < 4) {
      return {
        title: col,
        dataIndex: col,
        key: col,
        width: 150,
        fixed: "left",
      };
    } else {
      return {
        title: col,
        dataIndex: col,
        key: col,
        width: 150,
      };
    }
  });

  const handleOnChange = ({ file, fileList, event }) => {
    // console.log(file, fileList, event);
    // Validate the file here
    const isExcel =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    if (!isExcel) {
      message.error("You can only upload Excel files");
      setFileList([]);
    } else {
      setFileList(fileList);
      setTabledata([]);
    }

    // Update fileList with the validated file
  };

  const onRemove = (file) => {
    setFileList([]);
  };

  const downloadexcel = async () => {
    try {
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };

      const response = await axios.get(`${conf.baseurl}/sample_excel`, {
        responseType: "blob",
        headers: config.headers,
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sample.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      // console.error('Error downloading Excel file: ', error);
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error downloading Excel file: ", error);
      }
    }
  };

  const handleimport = async () => {
    try {
      const token = Cookies.get("token");
      console.log(token); // Replace 'yourTokenCookieName' with the actual name of your token cookie
      const config = {
        headers: {
          Authorization: token,
        },
      };

      // const response = await axios.get("http://localhost:5000/api/import", config);
      const response = await axios.get(`${conf.baseurl}/import`, config);
      if (response) {
        if (response.data.status == "S") {
          toast.success("Data updated Succesfully");
          setTabledata([]);
        } else if (response.data.status == "F") {
          toast.error(response.data.message);
          setTabledata([]);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error in uploading: ", error);
      }
    }
  };
  return (
    <div className="container">
      <div className="formWrapper">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            padding: 10,
          }}
        >
          <Button
            type="primary"
            style={{
              marginLeft: 5,
              alignSelf: "end",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={downloadexcel}
          >
            <FaArrowDown></FaArrowDown>Sample Excel
          </Button>
        </div>

        <h6 style={{ textAlign: "center" }}>
          Note: Please use the sample file to create your actual data file. You
          can remove unwanted columns. Blank fields,Non-decimal values will be
          ignored while importing.E-mail and Year required
        </h6>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <div style={{ marginRight: 20 }}>
            <Upload
              fileList={fileList}
              customRequest={customRequest}
              onChange={handleOnChange}
              onRemove={onRemove}
              beforeUpload={() => false}
              maxCount={1}
            >
              <Button>Select File & Upload</Button>
            </Upload>
          </div>

          <div>
            <Button key="submit" type="primary" onClick={customRequest}>
              Upload & Preview
            </Button>
          </div>
        </div>
        {tabledata.length > 0 && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: 30,
              }}
            >
              <Button key="submit" type="primary" onClick={handleimport}>
                Start Import
              </Button>
            </div>
            <div style={{ marginRight: 30, marginLeft: 30 }}>
              <Table
                columns={columns}
                dataSource={tabledata}
                className="grid-table importpageTable"
                bordered
                style={{ overflow: "auto", margin: 10, width: "100%" }}
                pagination={false}
                scroll={{
                  x: 500,
                  y: 400,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Importpage;
