import axios from "axios";
import { useState, useEffect } from "react";
import { Button, Table, Space, Spin, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { ToastContainer, toast } from "react-toastify";
import conf from "../config";
import Cookies from "js-cookie";
import { useMyContext } from "../context/Usecontext";
const Promotetablelist = ({ setIslogin }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { promotedataforedit, setPromotedataforedit } = useMyContext();

  const getdata = async () => {
    try {
      setLoading(true);
      const token = Cookies.get("token");
      const config = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.get(
        `${conf.baseurl}/getpromotedata`,
        config
      );
      if (response.data.status == "F") {
        // console.log(response.data);
        console.log("no data available");
      } else {
        // console.log(response.data);
        // console.log(response.data.data);
        setData(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Session expired, Please login again");
        setLoading(false);
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        setLoading(false);
        console.log("Error fetching data:", error.message);
      }
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  const columns = [
    {
      title: "Fund",
      dataIndex: "Fund",
      key: "Fund",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      render: (text) => {
        // Ensure the text is a number before formatting
        const amount = Number(text);
        // Format the number using .toLocaleString('en-US')
        const formattedAmount = amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return formattedAmount;
      },
    },
    {
      title: "BVCM %",
      dataIndex: "BVCM %",
      key: "BVCM %",
    },
    {
      title: "Start",
      dataIndex: "Start",
      key: "Start",
    },
    {
      title: "End",
      dataIndex: "End",
      key: "End",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => getdataforedit(record)}>
            <FiEdit />
          </Button>
        </Space>
      ),
    },
  ];

  const getdataforedit = (record) => {
    // console.log(record);
    setPromotedataforedit(record);
    navigate("/editpromotedata");
  };

  return (
    <div className="container">
      <Spin spinning={loading}>
        <div className="formWrapper">
          <Table
            columns={columns}
            bordered={true}
            dataSource={data}
            style={{ overflow: "auto", margin: 10 }}
            pagination={false}
            align="center"
            className="grid-table"
          />
        </div>
      </Spin>
    </div>
  );
};
export default Promotetablelist;
