import { Button, Form, Input } from "antd";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import conf from "../config";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useMyContext } from "../context/Usecontext";

const Loginpage = ({ handleLoginSuccess }) => {
  const navigate = useNavigate();
  const { isAdmin, setIsAdmin } = useMyContext();
  const { fname, setFname } = useMyContext();
  const { lname, setLname } = useMyContext();

  const onFinish = async (values) => {
    try {
      // console.log('Username:', values.username);
      // console.log('Password:', values.password);
      // const response = await axios.post("http://localhost:5000/api/login", values);
      const response = await axios.post(`${conf.baseurl}/login`, values);
      if (response.data.status === "S") {
        toast.success("Login successful.");
        if (response.data.isAdmin == 1) {
          navigate("/showdata");
          //   console.log(response.data.isAdmin);
          setIsAdmin(response.data.isAdmin);
          setFname(response.data.firstname);
          setLname(response.data.lastname);
          Cookies.set("token", response.data.token, { expires: 7 });
          Cookies.set("isAdmin", response.data.isAdmin, { expires: 7 });
          Cookies.set("isLoggedIn", "true", { expires: 7 });
          Cookies.set("firstname", response.data.firstname);
          Cookies.set("lastname", response.data.lastname);
          handleLoginSuccess();
        } else {
          navigate("/report");
          //   console.log(response.data.isAdmin);
          setIsAdmin(response.data.isAdmin);
          setFname(response.data.firstname);
          setLname(response.data.lastname);
          Cookies.set("token", response.data.token, { expires: 7 });
          Cookies.set("isAdmin", response.data.isAdmin, { expires: 7 });
          Cookies.set("isLoggedIn", "true", { expires: 7 });
          Cookies.set("firstname", response.data.firstname);
          Cookies.set("lastname", response.data.lastname);

          handleLoginSuccess();
        }
      } else {
        toast.error("Login failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="login-screen">
      <div className="loginBox">
        <div className="login-logo">
          <img src={require("../assets/login-logo.png")}></img>
        </div>
        <div className="loginForm">
          <Form
            name="loginForm"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="username"
              rules={[
                { required: true, message: "Please enter your username" },
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password / SSN"
              name="password"
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default Loginpage;
