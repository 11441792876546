import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Select,
  Spin,
} from "antd";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import conf from "../config";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { useMyContext } from "../context/Usecontext";

const Formpage = ({ datafedit, setEditdata, setIslogin }) => {
  const formRef = useRef(null);
  const selectref = useRef(null);
  const [getdatafedit, setGetdatafedit] = useState(null);
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { empidforedit, setEmpidforedit } = useMyContext();

  const getdetails = async () => {
    try {
      const token = Cookies.get("token");
      // console.log(token);
      const config = {
        headers: {
          Authorization: token,
        },
      };

      // const response = await axios.get("http://localhost:5000/api/getdetails",config)
      const response = await axios.get(`${conf.baseurl}/getdetails`, config);
      // console.log(response.data);
      if (response.data == "F") {
        console.log("no data availabele");
      } else {
        const data = response.data.filter((item) => item.isAdmin != 1);
        setData(data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login page
        toast.error("Session expired, Please login again");
        Cookies.remove("isLoggedIn");
        setIslogin(false);
        navigate("/");
      } else {
        // Handle other errors
        console.error("Error:", error.message);
      }
    }
  };
  useEffect(() => {
    getdetails();
    // console.log(datafedit);

    if (datafedit != null) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setGetdatafedit(datafedit);
      setEdit("true");
      console.log("id", datafedit.id);
      setEmpidforedit(datafedit.id);

      const firstDataItem = datafedit;
      // console.log(firstDataItem.Salary_Bonus?.highbonusestimate);
      formRef.current.setFieldsValue({
        // Year: firstDataItem.Year ? dayjs(firstDataItem.Year) : '',
        // info: firstDataItem.personid,
        Year: firstDataItem.Year ? dayjs(firstDataItem.Year) : "",
        info: firstDataItem.personid,
        Department: firstDataItem.Department,
        Title: firstDataItem.Title,
        Manager: firstDataItem.Manager,
        Salary_Bonus: {
          salary: firstDataItem?.Salary_Bonus?.salary,
          lowbonusestimate: firstDataItem?.Salary_Bonus?.lowbonusestimate,
          bonusestimate: firstDataItem?.Salary_Bonus?.bonusestimate,
          actualBonus: firstDataItem?.Salary_Bonus?.actualBonus,
          bonusTarget: firstDataItem?.Salary_Bonus?.bonusTarget,
          actualBonusReserve: firstDataItem?.Salary_Bonus?.actualBonusReserve,
          highbonusestimate: firstDataItem.Salary_Bonus?.highbonusestimate,
        },
        extrabonus: firstDataItem?.extrabonus,
        Match: firstDataItem?.Match,
        Footnote: firstDataItem.Footnote,

        Insurance: {
          medical: firstDataItem?.Insurance?.medical,
          lifeinsurance: firstDataItem?.Insurance?.lifeinsurance,
          vision: firstDataItem?.Insurance?.vision,
          Dental: firstDataItem?.Insurance?.Dental,
        },

        Promote_Distributions: {
          BVSHSSF_Raleigh: firstDataItem.Promote_Distributions?.BVSHSSF_Raleigh,
          BVSHSSF: firstDataItem.Promote_Distributions?.BVSHSSF,
          BAAG: firstDataItem.Promote_Distributions.BAAG,
          BAAG_II: firstDataItem.Promote_Distributions?.BAAG_II,
          BVSHSSF_Syracuse:
            firstDataItem.Promote_Distributions?.BVSHSSF_Syracuse,
          BVSEF_II: firstDataItem.Promote_Distributions?.BVSEF_II,
          BVSEF_III: firstDataItem.Promote_Distributions?.BVSEF_III,
          BVREP_IV: firstDataItem.Promote_Distributions?.BVREP_IV,
          BVREP_V: firstDataItem.Promote_Distributions?.BVREP_V,
        },
        Fund_allocations: {
          gateway: firstDataItem.Fund_allocations?.gateway,
          UCC: firstDataItem.Fund_allocations?.UCC,
          Cal_Regents_Boston:
            firstDataItem.Fund_allocations?.Cal_Regents_Boston,
          BVSHSSF_FA: firstDataItem.Fund_allocations?.BVSHSSF_FA,
          BVSEF_III_FA: firstDataItem.Fund_allocations?.BVSEF_III_FA,
          BVREP_IV_FA: firstDataItem.Fund_allocations?.BVREP_IV_FA,
          BVREP_V_FA: firstDataItem.Fund_allocations?.BVREP_V_FA,
          BVREP_VI_FA: firstDataItem.Fund_allocations?.BVREP_VI_FA,
          city: firstDataItem.Fund_allocations?.city,
          BVF_I: firstDataItem.Fund_allocations?.BVF_I,
          Cal_Regents_Seattle:
            firstDataItem.Fund_allocations?.Cal_Regents_Seattle,
          OP_Trust: firstDataItem.Fund_allocations?.OP_Trust,

          Samsung_Syracuse: firstDataItem.Fund_allocations?.Samsung_Syracuse,
          Samsung_Austin: firstDataItem.Fund_allocations?.Samsung_Austin,
          Harel: firstDataItem.Fund_allocations?.Harel,
          Stepstone: firstDataItem.Fund_allocations?.Stepstone,
          Gainesville: firstDataItem.Fund_allocations?.Gainesville,
          SHF_III: firstDataItem.Fund_allocations?.SHF_III,
          Cal_Regents_Raleigh:
            firstDataItem.Fund_allocations?.Cal_Regents_Raleigh,
        },
        Other_Earnings: {
          field1: firstDataItem.Other_Earnings?.field1,
          fieldvalue1: firstDataItem.Other_Earnings?.fieldvalue1,
          field2: firstDataItem.Other_Earnings?.field2,
          fieldvalue2: firstDataItem.Other_Earnings?.fieldvalue2,
          field3: firstDataItem.Other_Earnings?.field3,
          fieldvalue3: firstDataItem.Other_Earnings?.fieldvalue3,
        },

        EPP_Promote_Distributions: {
          BVREP_IV_EPD: firstDataItem.EPP_Promote_Distributions?.BVREP_IV_EPD,
          BVREP_V_EPD: firstDataItem.EPP_Promote_Distributions?.BVREP_V_EPD,
          BVREP_VI_EPD: firstDataItem.EPP_Promote_Distributions?.BVREP_VI_EPD,
          SHF_III_EPD: firstDataItem.EPP_Promote_Distributions?.SHF_III_EPD,
          BVF_I_EPD: firstDataItem.EPP_Promote_Distributions?.BVF_I_EPD,
          UCC_EPD: firstDataItem.EPP_Promote_Distributions?.UCC_EPD,
          Cal_Regents_Boston_EPD:
            firstDataItem.EPP_Promote_Distributions?.Cal_Regents_Boston_EPD,
          Cal_Regents_Syracuse_EPD:
            firstDataItem.EPP_Promote_Distributions?.Cal_Regents_Syracuse_EPD,
          Cal_Regents_Seattle_EPD:
            firstDataItem.EPP_Promote_Distributions?.Cal_Regents_Seattle_EPD,
          Harel_EPD: firstDataItem.EPP_Promote_Distributions?.Harel_EPD,
          Gainesville_EPD:
            firstDataItem.EPP_Promote_Distributions?.Gainesville_EPD,
          OP_Trust_EPD: firstDataItem.EPP_Promote_Distributions?.OP_Trust_EPD,
          Samsung_Syracuse_EPD:
            firstDataItem.EPP_Promote_Distributions?.Samsung_Syracuse_EPD,
          Samsung_Austin_EPD:
            firstDataItem.EPP_Promote_Distributions?.Samsung_Austin_EPD,
          Stepstone_EPD: firstDataItem.EPP_Promote_Distributions?.Stepstone_EPD,
          BVSHSSF_EPD: firstDataItem.EPP_Promote_Distributions?.BVSHSSF_EPD,
        },

        EPP_Promote_Distributions2: {
          BVREP_IV_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.BVREP_IV_EPD2,
          BVREP_V_EPD2: firstDataItem.EPP_Promote_Distributions2?.BVREP_V_EPD2,
          BVREP_VI_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.BVREP_VI_EPD2,
          SHF_III_EPD2: firstDataItem.EPP_Promote_Distributions2?.SHF_III_EPD2,
          BVF_I_EPD2: firstDataItem.EPP_Promote_Distributions2?.BVF_I_EPD2,
          UCC_EPD2: firstDataItem.EPP_Promote_Distributions2?.UCC_EPD2,
          Cal_Regents_Boston_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Cal_Regents_Boston_EPD2,
          Cal_Regents_Syracuse_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Cal_Regents_Syracuse_EPD2,
          Cal_Regents_Seattle_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Cal_Regents_Seattle_EPD2,
          Harel_EPD2: firstDataItem.EPP_Promote_Distributions2?.Harel_EPD2,
          Gainesville_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Gainesville_EPD2,
          OP_Trust_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.OP_Trust_EPD2,
          Samsung_Syracuse_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Samsung_Syracuse_EPD2,
          Samsung_Austin_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Samsung_Austin_EPD2,
          Stepstone_EPD2:
            firstDataItem.EPP_Promote_Distributions2?.Stepstone_EPD2,
          BVSHSSF_EPD2: firstDataItem.EPP_Promote_Distributions2?.BVSHSSF_EPD2,
        },

        EPP_Promote_Allocations: {
          BVREP_IV_EPA: firstDataItem.EPP_Promote_Allocations?.BVREP_IV_EPA,
          BVREP_V_EPA: firstDataItem.EPP_Promote_Allocations?.BVREP_V_EPA,
          BVREP_VI_EPA: firstDataItem.EPP_Promote_Allocations?.BVREP_VI_EPA,
          BVSHSSF_EPA: firstDataItem.EPP_Promote_Allocations?.BVSHSSF_EPA,
          SHF_III_EPA: firstDataItem.EPP_Promote_Allocations?.SHF_III_EPA,
          BVF_I_EPA: firstDataItem.EPP_Promote_Allocations?.BVF_I_EPA,
          UCC_EPA: firstDataItem.EPP_Promote_Allocations?.UCC_EPA,
          Cal_Regents_Boston_EPA:
            firstDataItem.EPP_Promote_Allocations?.Cal_Regents_Boston_EPA,
          Cal_Regents_Syracuse_EPA:
            firstDataItem.EPP_Promote_Allocations?.Cal_Regents_Syracuse_EPA,
          Cal_Regents_Seattle_EPA:
            firstDataItem.EPP_Promote_Allocations?.Cal_Regents_Seattle_EPA,
          Harel_EPA: firstDataItem.EPP_Promote_Allocations?.Harel_EPA,
          Gainesville_EPA:
            firstDataItem.EPP_Promote_Allocations?.Gainesville_EPA,
          OP_Trust_EPA: firstDataItem.EPP_Promote_Allocations?.OP_Trust_EPA,
          Samsung_Syracuse_EPA:
            firstDataItem.EPP_Promote_Allocations?.Samsung_Syracuse_EPA,
          Samsung_Austin_EPA:
            firstDataItem.EPP_Promote_Allocations?.Samsung_Austin_EPA,
          Stepstone_EPA: firstDataItem.EPP_Promote_Allocations?.Stepstone_EPA,
          BVAF_EPA: firstDataItem.EPP_Promote_Allocations?.BVAF_EPA,
          BVCSF_EPA: firstDataItem.EPP_Promote_Allocations?.BVCSF_EPA,
        },
      });
    }
  }, []);

  const onFinish = async (data) => {
    // console.log(data);
    const newObject = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        value === undefined ? null : value,
      ])
    );
    // console.log(newObject);
    newObject.Year = newObject.Year?.format("YYYY");
    console.log(data.Year);
    const { info, ...newdata } = newObject;
    if (edit) {
      const id = empidforedit;

      const editobj = {
        ...newdata,
        firstname: getdatafedit.firstname,
        lastname: getdatafedit.lastname,
        email: getdatafedit.email,
        personid: getdatafedit.personid,
      };

      const newDataItem = { ...editobj, id };
      console.log(newDataItem);
      try {
        // console.log(data);
        const token = Cookies.get("token");
        // console.log(token);
        const config = {
          headers: {
            Authorization: token,
          },
        };

        const response = await axios.put(
          `${conf.baseurl}/edit/${id}`,
          newDataItem,
          config
        );
        // console.log("Server response:", response.data);
        if (response.data.status == "S") {
          toast.success("Data updated successfully.", {
            position: "top-center",
          });
          // formRef.current.resetFields();
          // setEditdata(null);
          // setEdit(false);
          // navigate("/showdata");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login page
          toast.error("Session expired, Please login again");
          Cookies.remove("isLoggedIn");
          setIslogin(false);
          navigate("/");
        } else {
          // Handle other errors
          console.log("Error submitting form:", error);
          console.error("Error:", error.message);
        }
        // console.log('Error submitting form:', error);
      }
    } else {
      setLoading(true);
      const { info, ...newdata } = newObject;
      // console.log(newdata);
      // console.log(info);
      const { objVal } = info;

      const resultObject = {
        ...newdata,
        firstname: objVal.fname,
        lastname: objVal.lname,
        email: objVal.mail,
        personid: objVal.id,
      };
      const id = uuidv4();
      const newDataItem = { ...resultObject, id };

      try {
        // console.log(data);
        const token = Cookies.get("token");
        console.log(token);
        const config = {
          headers: {
            Authorization: token,
          },
        };

        // const response = await axios.post('http://localhost:5000/api/submit-form', newDataItem,config);
        const response = await axios.post(
          `${conf.baseurl}/submit-form`,
          newDataItem,
          config
        );
        // console.log("Server response:", response);
        if (response.data.message == "F") {
          toast("Data for this employee and year already exists.", {
            position: "top-center",
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
          setLoading(false);
          formRef.current.resetFields();
        } else if (response.data.message == "S") {
          toast.success("Form data submitted and saved.", {
            position: "top-center",
          });
          setLoading(false);
          formRef.current.resetFields();
          navigate("/showdata");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login page
          toast.error("Session expired, Please login again");
          Cookies.remove("isLoggedIn");
          setIslogin(false);
          navigate("/");
        } else {
          // Handle other errors
          console.log("Error submitting form:", error);
          console.error("Error:", error.message);
        }
        // console.log('Error submitting form:', error);
      }
    }
  };

  function disabledFutureYear(current) {
    // Disable all future years
    return current && dayjs(current).year() > dayjs().year();
  }

  const onFinishFailed = (errorInfo) => {
    // Scroll to the top of the page when form submission fails due to validation errors
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleYearChange = async (year) => {
    if (year) {
      const selectedYear = year.year();
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            Authorization: token,
          },
        };
        // Replace with your API endpoint
        const response = await axios.get(`${conf.baseurl}/getdata`, config);
        if (response.data) {
          const data = response.data.data.find(
            (item) =>
              item.personid === getdatafedit?.personid &&
              item.Year == selectedYear
          );
          console.log(data);
          setEditdata(data);
          if (data) {
            setEmpidforedit(data.id);
            formRef.current.setFieldsValue({
              // Year: year ? dayjs(year) : "",
              // info: data.personid,
              Department: data.Department,
              Title: data.Title,
              Manager: data.Manager,
              Salary_Bonus: data.Salary_Bonus || {
                salary: null,
                lowbonusestimate: null,
                highbonusestimate: null,
                bonusestimate: null,
                bonusTarget: null,
                actualBonus: null,
                actualBonusReserve: null,
              },
              extrabonus: data.extrabonus,
              Match: data.Match,
              Footnote: data.Footnote,
              Insurance: data.Insurance || {
                medical: null,
                lifeinsurance: null,
                Dental: null,
                vision: null,
              },
              Promote_Distributions: data.Promote_Distributions || {
                BVSHSSF_Raleigh: null,
                BVSHSSF: null,
                BAAG: null,
                BAAG_II: null,
                BVSHSSF_Syracuse: null,
                BVSEF_II: null,
                BVSEF_III: null,
                BVREP_IV: null,
                BVREP_V: null,
              },
              Fund_allocations: data.Fund_allocations || {
                gateway: null,
                UCC: null,
                Cal_Regents_Boston: null,
                BVSHSSF_FA: null,
                BVSEF_III_FA: null,
                BVREP_IV_FA: null,
                BVREP_V_FA: null,
                BVREP_VI_FA: null,
                city: null,
                BVF_I: null,
                Cal_Regents_Seattle: null,
                OP_Trust: null,
                Samsung_Syracuse: null,
                Samsung_Austin: null,
                Harel: null,
                Stepstone: null,
                Gainesville: null,
                SHF_III: null,
                Cal_Regents_Raleigh: null,
              },
              Other_Earnings: data.Other_Earnings || {
                field1: null,
                fieldvalue1: null,
                field2: null,
                fieldvalue2: null,
                field3: null,
                fieldvalue3: null,
              },
              EPP_Promote_Distributions: data.EPP_Promote_Distributions || {
                BVREP_IV_EPD: null,
                BVREP_V_EPD: null,
                BVREP_VI_EPD: null,
                SHF_III_EPD: null,
                BVF_I_EPD: null,
                UCC_EPD: null,
                Cal_Regents_Boston_EPD: null,
                Cal_Regents_Syracuse_EPD: null,
                Cal_Regents_Seattle_EPD: null,
                Harel_EPD: null,
                Gainesville_EPD: null,
                OP_Trust_EPD: null,
                Samsung_Syracuse_EPD: null,
                Samsung_Austin_EPD: null,
                Stepstone_EPD: null,
                BVSHSSF_EPD: null,
              },
              EPP_Promote_Allocations: data.EPP_Promote_Allocations || {
                BVREP_IV_EPA: null,
                BVREP_V_EPA: null,
                BVREP_VI_EPA: null,
                BVSHSSF_EPA: null,
                SHF_III_EPA: null,
                BVF_I_EPA: null,
                UCC_EPA: null,
                Cal_Regents_Boston_EPA: null,
                Cal_Regents_Syracuse_EPA: null,
                Cal_Regents_Seattle_EPA: null,
                Harel_EPA: null,
                Gainesville_EPA: null,
                OP_Trust_EPA: null,
                Samsung_Syracuse_EPA: null,
                Samsung_Austin_EPA: null,
                Stepstone_EPA: null,
                BVAF_EPA: null,
                BVCSF_EPA: null,
              },
            });
          } else {
            toast.error("For this year data is not present");
            setEmpidforedit(null);
            formRef.current.setFieldsValue({
              Department: null,
              Title: null,
              Manager: null,
              Salary_Bonus: {
                salary: null,
                lowbonusestimate: null,
                bonusestimate: null,
                actualBonus: null,
                bonusTarget: null,
                actualBonusReserve: null,
                highbonusestimate: null,
              },
              extrabonus: null,
              Match: null,
              Footnote: null,
              Insurance: {
                medical: null,
                lifeinsurance: null,
                vision: null,
                Dental: null,
              },
              Promote_Distributions: {
                BVSHSSF_Raleigh: null,
                BVSHSSF: null,
                BAAG: null,
                BAAG_II: null,
                BVSHSSF_Syracuse: null,
                BVSEF_II: null,
                BVSEF_III: null,
                BVREP_IV: null,
                BVREP_V: null,
              },
              Fund_allocations: {
                gateway: null,
                UCC: null,
                Cal_Regents_Boston: null,
                BVSHSSF_FA: null,
                BVSEF_III_FA: null,
                BVREP_IV_FA: null,
                BVREP_V_FA: null,
                BVREP_VI_FA: null,
                city: null,
                BVF_I: null,
                Cal_Regents_Seattle: null,
                OP_Trust: null,
                Samsung_Syracuse: null,
                Samsung_Austin: null,
                Harel: null,
                Stepstone: null,
                Gainesville: null,
                SHF_III: null,
                Cal_Regents_Raleigh: null,
              },
              Other_Earnings: {
                field1: null,
                fieldvalue1: null,
                field2: null,
                fieldvalue2: null,
                field3: null,
                fieldvalue3: null,
              },
              EPP_Promote_Distributions: {
                BVREP_IV_EPD: null,
                BVREP_V_EPD: null,
                BVREP_VI_EPD: null,
                SHF_III_EPD: null,
                BVF_I_EPD: null,
                UCC_EPD: null,
                Cal_Regents_Boston_EPD: null,
                Cal_Regents_Syracuse_EPD: null,
                Cal_Regents_Seattle_EPD: null,
                Harel_EPD: null,
                Gainesville_EPD: null,
                OP_Trust_EPD: null,
                Samsung_Syracuse_EPD: null,
                Samsung_Austin_EPD: null,
                Stepstone_EPD: null,
                BVSHSSF_EPD: null,
              },
              EPP_Promote_Allocations: {
                BVREP_IV_EPA: null,
                BVREP_V_EPA: null,
                BVREP_VI_EPA: null,
                BVSHSSF_EPA: null,
                SHF_III_EPA: null,
                BVF_I_EPA: null,
                UCC_EPA: null,
                Cal_Regents_Boston_EPA: null,
                Cal_Regents_Syracuse_EPA: null,
                Cal_Regents_Seattle_EPA: null,
                Harel_EPA: null,
                Gainesville_EPA: null,
                OP_Trust_EPA: null,
                Samsung_Syracuse_EPA: null,
                Samsung_Austin_EPA: null,
                Stepstone_EPA: null,
                BVAF_EPA: null,
                BVCSF_EPA: null,
              },
            });
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          toast.error("Session expired, Please login again");
          Cookies.remove("isLoggedIn");
          setIslogin(false);
          navigate("/");
        } else {
          console.error("Error fetching year data:", error.message);
        }
      }
    }
  };

  return (
    <div className="container">
      <Spin spinning={loading}>
        <Form
          layout="vertical"
          name="basic"
          // labelCol={{
          //     span: 10,
          // }}

          ref={formRef}
          // initialValues={{
          //     remember: true,
          // }}
          initialValues={{
            Salary_Bonus: {
              salary: null,
              lowbonusestimate: null,
              highbonusestimate: null,
              bonusestimate: null,
              bonusTarget: null,
              actualBonus: null,
              actualBonusReserve: null,
            },

            extrabonus: null,
            Footnote: null,
            Match: null,
            Insurance: {
              medical: null,
              lifeinsurance: null,
              Dental: null,
              vision: null,
            },
            Promote_Distributions: {
              BVSHSSF_Raleigh: null,
              BVSHSSF: null,
              BAAG: null,
              BAAG_II: null,
              BVSHSSF_Syracuse: null,
              BVSEF_II: null,
              BVSEF_III: null,
              BVREP_IV: null,
              BVREP_V: null,
            },
            Fund_allocations: {
              gateway: null,
              UCC: null,
              Cal_Regents_Boston: null,
              BVSHSSF_FA: null,
              BVSEF_III_FA: null,
              BVREP_IV_FA: null,
              BVREP_V_FA: null,
              BVREP_VI_FA: null,
              city: null,
              BVF_I: null,
              Cal_Regents_Seattle: null,
              OP_Trust: null,
              Samsung_Syracuse: null,
              Samsung_Austin: null,
              Harel: null,
              Stepstone: null,
              Gainesville: null,
              SHF_III: null,
              Cal_Regents_Raleigh: null,
            },
            Other_Earnings: {
              field1: null,
              fieldvalue1: null,
              field2: null,
              fieldvalue2: null,
              field3: null,
              fieldvalue3: null,
            },
            EPP_Promote_Distributions: {
              BVREP_IV_EPD: null,
              BVREP_V_EPD: null,
              BVREP_VI_EPD: null,
              SHF_III_EPD: null,
              BVF_I_EPD: null,
              UCC_EPD: null,
              Cal_Regents_Boston_EPD: null,
              Cal_Regents_Syracuse_EPD: null,
              Cal_Regents_Seattle_EPD: null,
              Harel_EPD: null,
              Gainesville_EPD: null,
              OP_Trust_EPD: null,
              Samsung_Syracuse_EPD: null,
              Samsung_Austin_EPD: null,
              Stepstone_EPD: null,
              BVSHSSF_EPD: null,
            },
            EPP_Promote_Distributions2: {
              BVREP_IV_EPD2: null,
              BVREP_V_EPD2: null,
              BVREP_VI_EPD2: null,
              SHF_III_EPD2: null,
              BVF_I_EPD2: null,
              UCC_EPD2: null,
              Cal_Regents_Boston_EPD2: null,
              Cal_Regents_Syracuse_EPD2: null,
              Cal_Regents_Seattle_EPD2: null,
              Harel_EPD2: null,
              Gainesville_EPD2: null,
              OP_Trust_EPD2: null,
              Samsung_Syracuse_EPD2: null,
              Samsung_Austin_EPD2: null,
              Stepstone_EPD2: null,
              BVSHSSF_EPD2: null,
            },
            EPP_Promote_Allocations: {
              BVREP_IV_EPA: null,
              BVREP_V_EPA: null,
              BVREP_VI_EPA: null,
              BVSHSSF_EPA: null,
              SHF_III_EPA: null,
              BVF_I_EPA: null,
              UCC_EPA: null,
              Cal_Regents_Boston_EPA: null,
              Cal_Regents_Syracuse_EPA: null,
              Cal_Regents_Seattle_EPA: null,
              Harel_EPA: null,
              Gainesville_EPA: null,
              OP_Trust_EPA: null,
              Samsung_Syracuse_EPA: null,
              Samsung_Austin_EPA: null,
              Stepstone_EPA: null,
              BVAF_EPA: null,
              BVCSF_EPA: null,
            },
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <div className="formWrapper">
            <Row>
              <Col span={7}>
                <Form.Item
                  label="Select Year"
                  name="Year"
                  mode="year"
                  rules={[{ required: true, message: "Please enter the year" }]}
                >
                  <DatePicker
                    picker="year"
                    format="YYYY"
                    // onChange={(year) => {
                    //   console.log(year);
                    // }}
                    onChange={(selectedyear) => {
                      if (edit) {
                        handleYearChange(selectedyear);
                      }
                    }}
                    // disabled={edit}
                    disabledDate={disabledFutureYear}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={10}>
                <Form.Item
                  label="Select Employee"
                  name="info"
                  rules={[
                    { required: true, message: "Please select the info" },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ width: "300px" }}
                    disabled={edit}
                    onSelect={(v, obj) => {
                      formRef.current.setFieldsValue({ info: obj });
                    }}
                  >
                    {data.map((val) => {
                      return (
                        <Select.Option objVal={val} value={val.id} key={val.id}>
                          {val.fname + " " + val.lname + " " + val.mail}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={7}>
                <Form.Item
                  label="Department"
                  // name="salary"
                  name="Department"
                  // rules={[{ required: true, message: 'Please enter the department' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={7}>
                <Form.Item
                  label="Manager"
                  // name="salary"
                  name="Manager"
                  // rules={[{ required: true, message: 'Please enter the manager' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={7}>
                <Form.Item
                  label="Title"
                  // name="salary"
                  name="Title"
                  // rules={[{ required: true, message: 'Please enter the title' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <hr></hr>

            <Row>
              <Col span={11}>
                <h4>Salary/Bonus</h4>
                <Form.Item name="Salary_Bonus">
                  <Form.Item
                    label="Salary"
                    // name="salary"
                    name={["Salary_Bonus", "salary"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Low Bonus Estimate"
                    // name="lowbonusestimate"
                    name={["Salary_Bonus", "lowbonusestimate"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="High bonus Estimate"
                    // name="highbonusestimate"
                    name={["Salary_Bonus", "highbonusestimate"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Bonus Estimate"
                    // name="bonusestimate"
                    name={["Salary_Bonus", "bonusestimate"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Bonus Target"
                    // name="bonusTarget"
                    name={["Salary_Bonus", "bonusTarget"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Actual Bonus"
                    // name="actualBonus"
                    name={["Salary_Bonus", "actualBonus"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Actual Bonus Reserve"
                    // name="actualBonusReserve"
                    name={["Salary_Bonus", "actualBonusReserve"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                    hidden={true}
                  >
                    <Input />
                  </Form.Item>
                </Form.Item>

                {/* <h4>Extra Bonuses</h4> */}

                <Form.Item
                  label="Extra Bonus"
                  name="extrabonus"
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                  // name={['ExtraBonuses', 'extrabonus']}
                >
                  <Input />
                </Form.Item>

                <h4>401(K) Match</h4>
                <Form.Item
                  label="401(K) Match"
                  name="Match"
                  rules={[
                    {
                      pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                      message: "Please enter a valid number.",
                    },
                  ]}
                  // name={['Match', 'kmatch401']}
                >
                  <Input />
                </Form.Item>

                <h4>Insurance</h4>
                <Form.Item name="Insurance">
                  <Form.Item
                    label="Medical (BV pays 61% of Premium)"
                    // name="medical"
                    name={["Insurance", "medical"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="LTD, STD, Life Insurance (100%)"
                    // name="lifeinsurance"
                    name={["Insurance", "lifeinsurance"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Dental (BV pays 50% of Premium)"
                    // name="Dental"
                    name={["Insurance", "Dental"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Vision (BV pays 50% of Premium)"
                    // name="vision"
                    name={["Insurance", "vision"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form.Item>

                <h4>Promote Distributions</h4>
                <Form.Item name="Promote_Distributions">
                  <Form.Item
                    label="BVSHSSF Raleigh"
                    // name="BVSHSSF_Raleigh"
                    name={["Promote_Distributions", "BVSHSSF_Raleigh"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVSHSSF"
                    // name="BVSHSSF"
                    name={["Promote_Distributions", "BVSHSSF"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BAAG"
                    // name="BAAG"
                    name={["Promote_Distributions", "BAAG"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BAAG II"
                    // name="BAAG_II"
                    name={["Promote_Distributions", "BAAG_II"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVSHSSF Syracuse"
                    // name="BVSHSSF_Syracuse"
                    name={["Promote_Distributions", "BVSHSSF_Syracuse"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVSEF II"
                    // name="BVSEF_II"
                    name={["Promote_Distributions", "BVSEF_II"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVSEF III"
                    // name="BVSEF_III"
                    name={["Promote_Distributions", "BVSEF_III"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVREP IV"
                    // name="BVREP_IV"
                    name={["Promote_Distributions", "BVREP_IV"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVREP V"
                    // name="BVREP_IV"

                    name={["Promote_Distributions", "BVREP_V"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form.Item>

                <h4> Promote Allocations %</h4>

                <Form.Item name="Fund_allocations">
                  <Form.Item
                    label="Gateway %"
                    // name="gateway"
                    name={["Fund_allocations", "gateway"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="UCC %"
                    // name="UCC"
                    name={["Fund_allocations", "UCC"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cal Regents Boston %"
                    // name="Cal_Regents_Boston"
                    name={["Fund_allocations", "Cal_Regents_Boston"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVSHSSF %"
                    // name="BVSHSSF_FA"
                    name={["Fund_allocations", "BVSHSSF_FA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVSEF III %"
                    // name="BVSEF_III_FA"
                    name={["Fund_allocations", "BVSEF_III_FA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVREP IV %"
                    // name="BVREP_IV_FA"
                    name={["Fund_allocations", "BVREP_IV_FA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVREP V %"
                    // name="BVREP_V_FA"
                    name={["Fund_allocations", "BVREP_V_FA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVREP VI %"
                    // name="BVREP_VI_FA"
                    name={["Fund_allocations", "BVREP_VI_FA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="City Select %"
                    // name="city"
                    name={["Fund_allocations", "city"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVF I %"
                    // name="BVF_I"
                    name={["Fund_allocations", "BVF_I"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cal Regents Seattle %"
                    // name="Cal_Regents_Seattle"
                    name={["Fund_allocations", "Cal_Regents_Seattle"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="OP Trust %"
                    // name="OP_Trust"
                    name={["Fund_allocations", "OP_Trust"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Samsung  Syracuse %"
                    // name="Samsung_Syracuse"
                    name={["Fund_allocations", "Samsung_Syracuse"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Samsung  Austin %"
                    // name="Samsung_Austin"
                    name={["Fund_allocations", "Samsung_Austin"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Harel %"
                    // name="Harel"
                    name={["Fund_allocations", "Harel"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Stepstone %"
                    name={["Fund_allocations", "Stepstone"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                    // name="Stepstone"
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Gainesville %"
                    // name="Gainesville"
                    name={["Fund_allocations", "Gainesville"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="SHF III %"
                    // name="SHF_III"
                    name={["Fund_allocations", "SHF_III"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Cal Regents – Raleigh %"
                    // name="SHF_III"
                    name={["Fund_allocations", "Cal_Regents_Raleigh"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form.Item>

                <h4>Other Earnings</h4>

                <Form.Item name="Other_Earnings">
                  <Form.Item
                    label="Field Title 1"
                    // name="gateway"
                    name={["Other_Earnings", "field1"]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Field value 1"
                    // name="gateway"
                    name={["Other_Earnings", "fieldvalue1"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Field Title 2"
                    // name="gateway"
                    name={["Other_Earnings", "field2"]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Field value 2"
                    // name="gateway"
                    name={["Other_Earnings", "fieldvalue2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Field Title 3"
                    // name="gateway"
                    name={["Other_Earnings", "field3"]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Field value 3"
                    // name="gateway"
                    name={["Other_Earnings", "fieldvalue3"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <h4>EPP Promote Distributions</h4>
                <Form.Item name="EPP_Promote_Distributions">
                  <Form.Item
                    label="BVREP IV"
                    // name="BVREP_IV_EPD"
                    name={["EPP_Promote_Distributions", "BVREP_IV_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVREP V"
                    // name="BVREP_V_EPD"
                    name={["EPP_Promote_Distributions", "BVREP_V_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVREP VI"
                    // name="BVREP_VI_EPD"
                    name={["EPP_Promote_Distributions", "BVREP_VI_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="SHF III"
                    // name="SHF_III_EPD"
                    name={["EPP_Promote_Distributions", "SHF_III_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVF I"
                    // name="BVF_I_EPD"
                    name={["EPP_Promote_Distributions", "BVF_I_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="UCC"
                    // name="UCC_EPD"
                    name={["EPP_Promote_Distributions", "UCC_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cal Regents Boston"
                    // name="Cal_Regents_Boston_EPD"
                    name={[
                      "EPP_Promote_Distributions",
                      "Cal_Regents_Boston_EPD",
                    ]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cal Regents Syracuse"
                    // name="Cal_Regents_Syracuse_EPD"
                    name={[
                      "EPP_Promote_Distributions",
                      "Cal_Regents_Syracuse_EPD",
                    ]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cal Regents  Seattle"
                    // name="Cal_Regents_Seattle_EPD"
                    name={[
                      "EPP_Promote_Distributions",
                      "Cal_Regents_Seattle_EPD",
                    ]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Harel"
                    // name="Harel_EPD"
                    name={["EPP_Promote_Distributions", "Harel_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Gainesville"
                    // name="Gainesville_EPD"
                    name={["EPP_Promote_Distributions", "Gainesville_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="OP Trust"
                    // name="OP_Trust_EPD"
                    name={["EPP_Promote_Distributions", "OP_Trust_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Samsung  Syracuse"
                    // name="Samsung_Syracuse_EPD"
                    name={["EPP_Promote_Distributions", "Samsung_Syracuse_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Samsung  Austin"
                    // name="Samsung_Austin_EPD"
                    name={["EPP_Promote_Distributions", "Samsung_Austin_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Stepstone"
                    // name="Stepstone_EPD"
                    name={["EPP_Promote_Distributions", "Stepstone_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVSHSSF"
                    // name="Stepstone_EPD"
                    name={["EPP_Promote_Distributions", "BVSHSSF_EPD"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form.Item>

                {/* <h4>EPP Promote Distributions 2</h4> */}
                <Form.Item name="EPP_Promote_Distributions2" hidden={true}>
                  <Form.Item
                    label="BVREP IV %"
                    // name="BVREP_IV_EPD"
                    name={["EPP_Promote_Distributions2", "BVREP_IV_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVREP V"
                    // name="BVREP_V_EPD"
                    name={["EPP_Promote_Distributions2", "BVREP_V_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVREP VI"
                    // name="BVREP_VI_EPD"
                    name={["EPP_Promote_Distributions2", "BVREP_VI_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="SHF III"
                    // name="SHF_III_EPD"
                    name={["EPP_Promote_Distributions2", "SHF_III_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVF I"
                    // name="BVF_I_EPD"
                    name={["EPP_Promote_Distributions2", "BVF_I_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="UCC"
                    // name="UCC_EPD"
                    name={["EPP_Promote_Distributions2", "UCC_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cal Regents Boston"
                    // name="Cal_Regents_Boston_EPD"
                    name={[
                      "EPP_Promote_Distributions2",
                      "Cal_Regents_Boston_EPD2",
                    ]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cal Regents Syracuse"
                    // name="Cal_Regents_Syracuse_EPD"
                    name={[
                      "EPP_Promote_Distributions2",
                      "Cal_Regents_Syracuse_EPD2",
                    ]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cal Regents  Seattle"
                    // name="Cal_Regents_Seattle_EPD"
                    name={[
                      "EPP_Promote_Distributions2",
                      "Cal_Regents_Seattle_EPD2",
                    ]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Harel"
                    // name="Harel_EPD"
                    name={["EPP_Promote_Distributions2", "Harel_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Gainesville"
                    // name="Gainesville_EPD"
                    name={["EPP_Promote_Distributions2", "Gainesville_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="OP Trust"
                    // name="OP_Trust_EPD"
                    name={["EPP_Promote_Distributions2", "OP_Trust_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Samsung  Syracuse"
                    // name="Samsung_Syracuse_EPD"
                    name={[
                      "EPP_Promote_Distributions2",
                      "Samsung_Syracuse_EPD2",
                    ]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Samsung  Austin"
                    // name="Samsung_Austin_EPD"
                    name={["EPP_Promote_Distributions2", "Samsung_Austin_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Stepstone"
                    // name="Stepstone_EPD"
                    name={["EPP_Promote_Distributions2", "Stepstone_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVSHSSF %"
                    // name="Stepstone_EPD"
                    name={["EPP_Promote_Distributions2", "BVSHSSF_EPD2"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form.Item>

                <h4>EPP Promote Share %</h4>
                <Form.Item name="EPP_Promote_Allocations">
                  <Form.Item
                    label="BVREP IV %"
                    // name="BVREP_IV_EPA"
                    name={["EPP_Promote_Allocations", "BVREP_IV_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVREP V %"
                    // name="BVREP_V_EPA"
                    name={["EPP_Promote_Allocations", "BVREP_V_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVREP VI %"
                    // name="BVREP_VI_EPA"
                    name={["EPP_Promote_Allocations", "BVREP_VI_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVSHSSF %"
                    // name="BVSHSSF_EPA"
                    name={["EPP_Promote_Allocations", "BVSHSSF_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="SHF III %"
                    // name="SHF_III_EPA"
                    name={["EPP_Promote_Allocations", "SHF_III_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVF I %"
                    // name="BVF_I_EPA"
                    name={["EPP_Promote_Allocations", "BVF_I_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="UCC %"
                    // name="UCC_EPA"
                    name={["EPP_Promote_Allocations", "UCC_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cal Regents Boston %"
                    // name="Cal_Regents_Boston_EPA"
                    name={["EPP_Promote_Allocations", "Cal_Regents_Boston_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cal Regents Syracuse %"
                    // name="Cal_Regents_Syracuse_EPA"
                    name={[
                      "EPP_Promote_Allocations",
                      "Cal_Regents_Syracuse_EPA",
                    ]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Cal Regents Seattle %"
                    // name="Cal_Regents_Seattle_EPA"
                    name={[
                      "EPP_Promote_Allocations",
                      "Cal_Regents_Seattle_EPA",
                    ]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Harel %"
                    // name="Harel_EPA"
                    name={["EPP_Promote_Allocations", "Harel_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Gainesville %"
                    // name="Gainesville_EPA"
                    name={["EPP_Promote_Allocations", "Gainesville_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="OP Trust %"
                    // name="OP_Trust_EPA"
                    name={["EPP_Promote_Allocations", "OP_Trust_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Samsung Syracuse %"
                    // name="Samsung_Syracuse_EPA"
                    name={["EPP_Promote_Allocations", "Samsung_Syracuse_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Samsung Austin %"
                    // name="Samsung_Austin_EPA"
                    name={["EPP_Promote_Allocations", "Samsung_Austin_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Stepstone %"
                    // name="Stepstone_EPA"
                    name={["EPP_Promote_Allocations", "Stepstone_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="BVAF %"
                    // name="Stepstone_EPA"
                    name={["EPP_Promote_Allocations", "BVAF_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="BVCSF %"
                    // name="Stepstone_EPA"
                    name={["EPP_Promote_Allocations", "BVCSF_EPA"]}
                    rules={[
                      {
                        pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                        message: "Please enter a valid number.",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Form.Item>
                <h4>Footnote</h4>
                <Form.Item
                  label="Footnote"
                  name="Footnote"
                  // rules={[
                  //     {
                  //         pattern: /^\d*\.?\d*$/, // Regular expression to allow only numbers and decimal values
                  //         message: 'Please enter a valid number.',
                  //     },
                  // ]}
                  // name={['Match', 'kmatch401']}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <div className="btns-row">
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ paddingLeft: 20, paddingRight: 20 }}
                  disabled={edit ? (!empidforedit ? true : false) : false}
                >
                  {edit ? "Save" : "Submit"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
export default Formpage;
